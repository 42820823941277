import React from 'react';
import '../../StylesCSS/ButtonsLayout.css';
import { School, Home, Input, Language, ArrowBack } from '@mui/icons-material'; // Import des icônes

const PageLayout3 = ({ setActiveSection }) => {
    const handleSectionChange = (section) => {
        setActiveSection(section);
    };

    return (
        <div style={{ maxWidth: '600px', margin: 'auto', textAlign: 'center' }}> {/* Centre le contenu */}
            <h2 style={{ textAlign: 'right' }}> : المحاضر</h2>
            <div className="buttons-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}> {/* Conteneur centré */}

                <button
                    className="btn btn-right"
                    onClick={() => handleSectionChange('RepartitionDesElevesOrientes')}
                    style={{
                        backgroundColor: '#33B5E6', // Couleur spécifique
                        color: 'white',
                        padding: '10px 20px',
                        margin: '10px 0',
                        borderRadius: '8px',
                        border: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%', // Largeur des boutons pour uniformité
                        maxWidth: '400px', // Limite la taille des boutons
                    }}
                >
                    <School style={{ marginRight: '8px' }} /> {/* Icône */}
                    توزيع التلاميد الموجهين حسب الجدع و الجنس و اللغة الاجنبية الثانية
                </button>

                <button
                    className="btn btn-right"
                    onClick={() => handleSectionChange('EtudiantsInterieurVille')}
                    style={{
                        backgroundColor: '#33B5E6',
                        color: 'white',
                        padding: '10px 20px',
                        margin: '10px 0',
                        borderRadius: '8px',
                        border: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        maxWidth: '400px',
                    }}
                >
                    <Home style={{ marginRight: '8px' }} />
                     
          جدول ( ج )  مكرر                 
</button>

                {/* Button: ادخال العتبة */}
                <button
                    className="btn btn-right"
                    onClick={() => handleSectionChange('DistributionStudentsOutsideCity')}
                    style={{
                        backgroundColor: '#33B5E6',
                        color: 'white',
                        padding: '10px 20px',
                        margin: '10px 0',
                        borderRadius: '8px',
                        border: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        maxWidth: '400px',
                    }}
                >
                    <Home style={{ marginRight: '8px' }} />
                    جدول ( ج )                
                    </button>

                {/* Button: ادخال الملاحظات */}
                <button
                    className="btn btn-right"
                    onClick={() => handleSectionChange('MinutesOfDepartmentCouncilDecision')}
                    style={{
                        backgroundColor: '#33B5E6',
                        color: 'white',
                        padding: '10px 20px',
                        margin: '10px 0',
                        borderRadius: '8px',
                        border: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        maxWidth: '400px',
                    }}
                >
                    <Input style={{ marginRight: '8px' }} />
              محضر قرار مجلس القسم الخاص بالتوجيه                
           </button>
  
                 <button
                    className="btn btn-right"
                    onClick={() => handleSectionChange('MinutesOfDepartmentCouncilDecision1')}
                    style={{
                        backgroundColor: '#33B5E6',
                        color: 'white',
                        padding: '10px 20px',
                        margin: '10px 0',
                        borderRadius: '8px',
                        border: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        maxWidth: '400px',
                    }}
                >
                    <Input style={{ marginRight: '8px' }} />
محضر تقييم اشغال مجالس الاقسام الخاصة بالتوجيه                
      </button>
                   <button
                    className="btn btn-right"
                    onClick={() => handleSectionChange('ResultatsDesConseils')}
                    style={{
                        backgroundColor: '#33B5E6',
                        color: 'white',
                        padding: '10px 20px',
                        margin: '10px 0',
                        borderRadius: '8px',
                        border: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        maxWidth: '400px',
                    }}
                >
                    <Input style={{ marginRight: '8px' }} />
النتائج العددية لمجالس الاقسام الخاصة بالتوجيه السنة الثالثة اعدادي                </button>

                {/* Button: ادخال اللغة الاجنبية الثانية */}
             {/*    <button
                    className="btn btn-right"
                    onClick={() => handleSectionChange('DeuxiemeLangueSelonDepartement')}
                    style={{
                        backgroundColor: '#33B5E6',
                        color: 'white',
                        padding: '10px 20px',
                        margin: '10px 0',
                        borderRadius: '8px',
                        border: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        maxWidth: '400px',
                    }}
                >
                    <Language style={{ marginRight: '8px' }} />
                    ادخال اللغة الاجنبية الثانية حسب القسم
                </button>
 */}
                {/* Button: Retour */}
                <button
                    className="btn btn-left"
                    style={{
                        backgroundColor: '#FF6347', // Couleur spécifique pour le bouton retour
                        color: 'white',
                        padding: '10px 20px',
                        margin: '20px 0',
                        borderRadius: '8px',
                        border: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        maxWidth: '400px',
                    }}
                    onClick={() => handleSectionChange('PageLayout')}
                >
                    <ArrowBack style={{ marginRight: '8px' }} />
                    Retour
                </button>
            </div>
        </div>
    );
};

export default PageLayout3;

import React from 'react';
import Sidebar from '../components/dashboardContent/Sidebar';
import AllFichesTechniques from './dashboardContent/AllFichesTechniques';
import FicheTechnique from './dashboardContent/FicheTechnique';
import Form from '../components/dashboardContent/Form';
import Report from '../components/dashboardContent/Report';
import ImportFileExcel from '../components/dashboardContent/ImportFileExcel';
import PageLayout from '../components/dashboardContent/PageLayout';
import OriginalInstitution from '../components/dashboardContent/OriginalInstitution';
import StudentSelector from '../components/dashboardContent/StudentSelector';
import ContactUs from '../components/dashboardContent/ContactUs';
import EtablissementAccueil from '../components/dashboardContent/EtablissementAccueil';
import PageLayout2 from '../components/dashboardContent/PageLayout2';
import LeSeuil from '../components/dashboardContent/LeSeuil';
import InputNotes from '../components/dashboardContent/InputNotes';
import DeuxiemeLangueSelonDepartement from '../components/dashboardContent/DeuxiemeLangueSelonDepartement';
import PageLayout3 from '../components/dashboardContent/PageLayout3';
import EtudiantsInterieurVille from '../components/dashboardContent/EtudiantsInterieurVille';
import RepartitionDesElevesOrientes  from '../components/dashboardContent/RepartitionDesElevesOrientes';
import DistributionStudentsOutsideCity  from '../components/dashboardContent/DistributionStudentsOutsideCity';
import MinutesOfDepartmentCouncilDecision  from '../components/dashboardContent/MinutesOfDepartmentCouncilDecision';
import MinutesOfDepartmentCouncilDecision1  from '../components/dashboardContent/MinutesOfDepartmentCouncilDecision1';

import ResultatsDesConseils  from '../components/dashboardContent/ResultatsDesConseils';



import Image1 from '../Images/Photo1.PNG';
import '../StylesCSS/Dashboard.css';
import { useState } from 'react';


const Dashboard = ({ handleLogout }) => {
  const [activeSection, setActiveSection] = useState('form');

  const renderSection = () => {
    switch (activeSection) {
      case 'AllFichesTechniques':
        return <AllFichesTechniques />;
      case 'FicheTechnique':
        return <FicheTechnique setActiveSection={setActiveSection}/>;
      case 'form':
        return <Form />;
      case 'Report':
        return <Report/>
      case 'PageLayout':
        return <PageLayout setActiveSection={setActiveSection} />;
      case 'ImportFileExcel':
        return <ImportFileExcel setActiveSection={setActiveSection} />;
      case 'OriginalInstitution':
        return <OriginalInstitution setActiveSection={setActiveSection} />;
      case 'StudentSelector':
          return <StudentSelector setActiveSection={setActiveSection} />;
      case 'ContactUs':
            return <ContactUs setActiveSection={setActiveSection} />;
      case 'PageLayout2':
            return <PageLayout2 setActiveSection={setActiveSection} />;
      case 'EtablissementAccueil':
            return <EtablissementAccueil setActiveSection={setActiveSection} />;
      case 'LeSeuil':
            return <LeSeuil setActiveSection={setActiveSection} />;
      case 'InputNotes':
            return <InputNotes setActiveSection={setActiveSection} />;
      case 'DeuxiemeLangueSelonDepartement':
              return <DeuxiemeLangueSelonDepartement setActiveSection={setActiveSection} />;
      case 'PageLayout3':
              return <PageLayout3 setActiveSection={setActiveSection} />;
      case 'RepartitionDesElevesOrientes':
             return <RepartitionDesElevesOrientes setActiveSection={setActiveSection} />;
      case 'EtudiantsInterieurVille':
            return <EtudiantsInterieurVille setActiveSection={setActiveSection} />;
      case 'DistributionStudentsOutsideCity':
            return <DistributionStudentsOutsideCity setActiveSection={setActiveSection} />;
      case 'MinutesOfDepartmentCouncilDecision':
            return <MinutesOfDepartmentCouncilDecision setActiveSection={setActiveSection} />;
      case 'MinutesOfDepartmentCouncilDecision1':
            return <MinutesOfDepartmentCouncilDecision1 setActiveSection={setActiveSection} />;
      case 'ResultatsDesConseils':
            return <ResultatsDesConseils setActiveSection={setActiveSection} />;
            
      default:
        return <Form />;
    }
  };

  return (
    <div style={{ display: "flex", height: "100vh" }} className="d-flex" id="wrapper">
      <Sidebar setActiveSection={setActiveSection} handleLogout={handleLogout} />
      <div id="page-content-wrapper">
        <div className="container-fluid">
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <img src={Image1} alt="Dashboard Header" style={{ width: '400px', height: '100px' }} />
          </div>
          {renderSection()}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

import React from 'react';
import '../../StylesCSS/ButtonsLayout.css';
import { School, Home, Input, Language, ArrowBack } from '@mui/icons-material'; // Import des icônes

const PageLayout2 = ({ setActiveSection }) => {
    const handleSectionChange = (section) => {
        setActiveSection(section);
    };

    return (
        <div style={{ maxWidth: '600px', margin: 'auto', textAlign: 'center' }}> {/* Centre le contenu */}
            <h2 style={{ textAlign: 'right' }}> : تدبير مجالس الأقســــام </h2>
            <div className="buttons-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}> {/* Conteneur centré */}
                {/* Button: المؤسسة الأصلية */}
                <button
                    className="btn btn-right"
                    onClick={() => handleSectionChange('OriginalInstitution')}
                    style={{
                        backgroundColor: '#33B5E6', // Couleur spécifique
                        color: 'white',
                        padding: '10px 20px',
                        margin: '10px 0',
                        borderRadius: '8px',
                        border: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%', // Largeur des boutons pour uniformité
                        maxWidth: '400px', // Limite la taille des boutons
                    }}
                >
                    <School style={{ marginRight: '8px' }} /> {/* Icône */}
                    المؤسسة الأصلية
                </button>

                {/* Button: مؤسسة الاستقبال */}
                <button
                    className="btn btn-right"
                    onClick={() => handleSectionChange('EtablissementAccueil')}
                    style={{
                        backgroundColor: '#33B5E6',
                        color: 'white',
                        padding: '10px 20px',
                        margin: '10px 0',
                        borderRadius: '8px',
                        border: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        maxWidth: '400px',
                    }}
                >
                    <Home style={{ marginRight: '8px' }} />
                    مؤسسة الاستقبال
                </button>

                {/* Button: ادخال العتبة */}
                <button
                    className="btn btn-right"
                    onClick={() => handleSectionChange('LeSeuil')}
                    style={{
                        backgroundColor: '#33B5E6',
                        color: 'white',
                        padding: '10px 20px',
                        margin: '10px 0',
                        borderRadius: '8px',
                        border: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        maxWidth: '400px',
                    }}
                >
                    <Input style={{ marginRight: '8px' }} />
                    ادخال العتبة
                </button>

                {/* Button: ادخال الملاحظات */}
                <button
                    className="btn btn-right"
                    onClick={() => handleSectionChange('InputNotes')}
                    style={{
                        backgroundColor: '#33B5E6',
                        color: 'white',
                        padding: '10px 20px',
                        margin: '10px 0',
                        borderRadius: '8px',
                        border: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        maxWidth: '400px',
                    }}
                >
                    <Input style={{ marginRight: '8px' }} />
                    ادخال الملاحظات
                </button>

                {/* Button: ادخال اللغة الاجنبية الثانية */}
                <button
                    className="btn btn-right"
                    onClick={() => handleSectionChange('DeuxiemeLangueSelonDepartement')}
                    style={{
                        backgroundColor: '#33B5E6',
                        color: 'white',
                        padding: '10px 20px',
                        margin: '10px 0',
                        borderRadius: '8px',
                        border: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        maxWidth: '400px',
                    }}
                >
                    <Language style={{ marginRight: '8px' }} />
                    ادخال اللغة الاجنبية الثانية حسب القسم
                </button>

                {/* Button: Retour */}
                <button
                    className="btn btn-left"
                    style={{
                        backgroundColor: '#FF6347', // Couleur spécifique pour le bouton retour
                        color: 'white',
                        padding: '10px 20px',
                        margin: '20px 0',
                        borderRadius: '8px',
                        border: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        maxWidth: '400px',
                    }}
                    onClick={() => handleSectionChange('PageLayout')}
                >
                    <ArrowBack style={{ marginRight: '8px' }} />
                    Retour
                </button>
            </div>
        </div>
    );
};

export default PageLayout2;

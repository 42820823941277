import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import { UserProvider } from './context/UserContext';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ImportFileExcel from './components/dashboardContent/ImportFileExcel'; 
import PageLayout from './components/dashboardContent/PageLayout';
import PageLayout2 from './components/dashboardContent/PageLayout2';


const App = () => {
  const [view, setView] = useState('login');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Check authentication status on initial load
  useEffect(() => {
    const authStatus = localStorage.getItem('isAuthenticated');
    if (authStatus === 'true') {
      setIsAuthenticated(true);
    }
  }, []);

  const handleAuth = (status) => {
    setIsAuthenticated(status);
    localStorage.setItem('isAuthenticated', status);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated');
    setView('login');
  };

  return (
    <UserProvider>
      <Router>
        <div>
          {!isAuthenticated ? (
            view === 'login' ? (
              <Login setAuth={handleAuth} showRegister={() => setView('register')} />
            ) : (
              <Register setAuth={handleAuth} showLogin={() => setView('login')} />
            )
          ) : (
            <Routes>
              <Route path="/" element={<Dashboard handleLogout={handleLogout} />} />
              <Route path="/ImportFileExcel" element={<ImportFileExcel />} />
              <Route path="/pagelayout" element={<PageLayout />} />
              <Route path="/pagelayout2" element={<PageLayout2 />} />


              {/* Ajoute d'autres routes ici si nécessaire */}
            </Routes>
          )}
        </div>
      </Router>
    </UserProvider>
  );
};

export default App;

import React, { useState ,useEffect } from 'react';
import 'jspdf-autotable';
import jsPDF from 'jspdf'; 
import '../../StylesCSS/MinutesOfDepartmentCouncilDecision1.css'; // Assurez-vous d'importer ce fichier CSS
import { MenuItem, FormControl, Select, InputLabel, Box, Typography, Button ,TextField } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';



const MinutesOfDepartmentCouncilDecision1 = ({ setActiveSection })  => {
  const [institutions, setInstitutions] = useState(['']);
  const [reasons, setReasons] = useState(['']);
  const [members, setMembers] = useState([{ fullName: '', role: '' }]);
  const [institutionName, setInstitutionName] = useState('');
  const [date, setDate] = useState('');
  const [councilDate, setCouncilDate] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const [studentName, setStudentName] = useState('');
  const [className, setClassName] = useState('');
  const [notes, setNotes] = useState('');
  const [valueDate, setValueDate] = useState('');
  const [valuePlace, setValuePlace] = useState('');

  const handleInstitutionChange = (index, value) => {
    console.log(`Institution ${index + 1}:`, value);
    const updatedInstitutions = [...institutions];
    updatedInstitutions[index] = value;
    setInstitutions(updatedInstitutions);
  };

  const handleAddInstitution = () => {
    setInstitutions([...institutions, '']);
  };

  const handleRemoveInstitution = (index) => {
    const updatedInstitutions = institutions.filter((_, i) => i !== index);
    setInstitutions(updatedInstitutions);
  };

  const handleReasonChange = (index, value) => {
    console.log(`Reason ${index + 1}:`, value);
    const updatedReasons = [...reasons];
    updatedReasons[index] = value;
    setReasons(updatedReasons);
  };

  const handleAddReason = () => {
    setReasons([...reasons, '']);
  };

  const handleRemoveReason = (index) => {
    const updatedReasons = reasons.filter((_, i) => i !== index);
    setReasons(updatedReasons);
  };

  const handleMemberChange = (index, field, value) => {
    const updatedMembers = [...members];
    updatedMembers[index][field] = value;
    setMembers(updatedMembers);
    console.log(`Member ${index + 1} ${field}:`, value);
  };

  const handleAddMember = () => {
    setMembers([...members, { fullName: '', role: '' }]);
  };

  const handleRemoveMember = (index) => {
    const updatedMembers = members.filter((_, i) => i !== index);
    setMembers(updatedMembers);
  };

  const handleGoBack = (section) => {
    setActiveSection(section);
    console.log("Retour");
  };

  useEffect(() => {
    const loadFonts = async () => {
      try {
        const response = await fetch('/fonts/NotoSansArabic-Regular.ttf');
        if (!response.ok) throw new Error('Font not found');
        const buffer = await response.arrayBuffer();
        const fontData = String.fromCharCode(...new Uint8Array(buffer));
        jsPDF.API.addFileToVFS('NotoSansArabic-Regular.ttf', fontData);
        jsPDF.API.addFont('NotoSansArabic-Regular.ttf', 'NotoSansArabic', 'normal');
      } catch (error) {
        console.error('Error loading font:', error);
      }
    };
  
    loadFonts();
  }, []); // Notez le tableau vide [] pour empêcher les ré-exécutions
  
  const addNotesSection = (doc, x, y, width, initialHeight, lineSpacing, notes) => {
    // Limite initiale des lignes
    const maxInitialLines = 5;
    const maxTextWidth = width + 150 ; // Largeur utilisable pour le texte
    const lines = doc.splitTextToSize(notes, maxTextWidth); // Diviser les notes en lignes
  
    // Calculer la hauteur dynamique
    const requiredHeight = lines.length * lineSpacing + 20; // Hauteur nécessaire en fonction des lignes
    const height = Math.max(initialHeight, requiredHeight); // Ajuster la hauteur uniquement si elle dépasse l'initiale
  
    // Dessiner le rectangle arrondi avec la hauteur ajustée
    doc.roundedRect(x, y, width, height, 10, 10); // 10 pour le rayon des coins
  
    // Ajouter le titre "ملاحظات" centré en haut du rectangle
    doc.setFontSize(12);
    doc.text('ملاحظات', x + width / 2, y - 5, { align: 'center' });
  

    // Ajouter chaque ligne des notes au PDF    
    const startX = 540; // Décalage gauche
    let currentY = y + 15; // Position Y initiale (sous le bord supérieur)
  
    doc.setFontSize(11); // Taille de police pour les notes
    lines.forEach((line) => {
      if (currentY < y + height - 10) {
        doc.text(line, startX, currentY, { align: 'right' });
        currentY += lineSpacing; // Espacement vertical
      }
    });
  };
  
  

  const handleExportPDF = async () => {
    const doc = new jsPDF('p', 'pt', 'a4');

    // Ajouter la police arabe
    doc.addFont('/fonts/NotoSansArabic-Regular.ttf', 'NotoSansArabic', 'normal');
    doc.setFont('NotoSansArabic', 'normal');
    doc.setFontSize(12);

    const img = new Image();
    img.src = '/images/Photo1.PNG';
    await new Promise(resolve => {
      img.onload = resolve;
    });
  
    const imgWidth = 250;
    const imgHeight = (img.height * imgWidth) / img.width;
    doc.addImage(img, 'PNG', (doc.internal.pageSize.width - imgWidth) / 2, 10, imgWidth, imgHeight);
  
    doc.setFont('NotoSansArabic');
  
    doc.setFontSize(11);
    doc.setTextColor(0, 0, 0); // Couleur noire pour les trois premières lignes
  
    // Ajouter les trois premiers textes avec les nouvelles propriétés
    doc.text(' الأكاديمية الجهوية:  طنجة - تطوان - الحسيمة', doc.internal.pageSize.width / 2, imgHeight + 15, { align: 'center' });
    doc.text('  مديرية المضيق - الفنيدق   ', doc.internal.pageSize.width / 2, imgHeight + 30, { align: 'center' });
    doc.text('  مصلحة تأطير المؤسسات التعليمية و التوجيه  ', doc.internal.pageSize.width / 2, imgHeight + 45, { align: 'center' });
  
    const pageWidth = doc.internal.pageSize.width;
    const centerX = pageWidth / 2;

    // Titre principal (Conseil)
    doc.setFontSize(14);
    doc.text('  محضر تقييم اشغال مجالس الاقسام الخاصة بالتوجيه ', 300, imgHeight + 70, { align: 'center' });
    doc.setLineWidth(2);
    // Ajouter une ligne sous le texte
    const lineMargin = 150; // Marge de chaque côté de la ligne
    doc.line(lineMargin, imgHeight + 75, pageWidth - lineMargin , imgHeight + 75); 

    doc.setFontSize(12);
    doc.text('  المؤسسة : ' + institutionName, doc.internal.pageSize.width - 120, imgHeight + 100, { align: 'right' });
    doc.text('القسم :'  + className , doc.internal.pageSize.width / 2 - 100, imgHeight + 100, { align: 'center' });

     // Ajout de la section "ملاحظات" avec les notes
  const x = 50; // Position X du rectangle
  const y = imgHeight + 120; // Position Y du rectangle
  const width = 500; // Largeur du rectangle
  const initialHeight = 100; // Hauteur initiale du rectangle
  const lineSpacing = 15; // Espacement entre les lignes

  addNotesSection(doc, x, y, width, initialHeight, lineSpacing, notes);
  const lineMargin1 = 220;

  doc.setFontSize(14);
  doc.text( '  توقيعات اعضاء المجلس  '  , 300, imgHeight + 260, { align: 'center' });
  doc.setLineWidth(2);
  // Ajouter une ligne sous le texte
  doc.line(lineMargin1, imgHeight + 265, pageWidth - lineMargin1 , imgHeight + 265); 

    // Ajout du texte reçu
    doc.setFontSize(12);
    doc.setFont('NotoSansArabic');
    // Créez le tableau avec autoTable une seule fois
    const tableColumnHeaders = ['التوقيع', ' الصفة', 'الاسم الكامل'];
    const tableRows = members.map(member => ['', member.role, member.fullName]);
    doc.autoTable({
      head: [tableColumnHeaders],
      body: tableRows,
      startY:imgHeight + 300,
      theme: 'grid',
      styles: { font: 'NotoSansArabic', halign: 'right' },
      columnStyles: {
        0: { cellWidth: 150 },
        1: { cellWidth: 180 },
        2: { cellWidth: 180 },
      },
    });
    doc.setFontSize(11);


    doc.setFont('NotoSansArabic');
    doc.text( ' حـــرر بــ : ' + valueDate + '  ' +  'في  : ' + valuePlace, pageWidth / 2, 710, { align: 'center' });

    // Souligner le texte
    const textWidth = doc.getTextWidth('  خاتم و توقيع رئيس المجلس ');
    const startX = 120; // Position X de début
    const startY = 750; // Position Y légèrement en dessous du texte
    doc.line(startX, startY, startX + textWidth, startY);   
    
    doc.text('  خاتم و توقيع رئيس المجلس ', 120, 745);

    // Souligner le texte
    const textWidth1 = doc.getTextWidth('  توقيع اطار التوجيه التربوي');
    const startX1 = 400; // Position X de début
    const startY1 = 750; // Position Y légèrement en dessous du texte
    doc.line(startX1, startY1, startX1 + textWidth1, startY1);    
    doc.text('  توقيع اطار التوجيه التربوي', 400, 745);


    // Enregistrement du fichier PDF
    doc.save('محضر تقييم اشغال مجالس الاقسام الخاصة بالتوجيه.pdf');
};


  return (
    <div className="form-container">
      <h2 className="form-title">      محضر تقييم اشغال مجالس الاقسام الخاصة بالتوجيه   </h2>

      <div className="form-group">
        <label style={{ display: 'block', textAlign: 'right' }}> : المؤسسة </label>
        <input
          type="text"
          className="form-control"
          placeholder="اسم المؤسسة"
          value={institutionName}
          onChange={(e) => {
            setInstitutionName(e.target.value);
            console.log('Institution Name:', e.target.value);
          }}
          required
          style={{ display: 'block', textAlign: 'right' }}
          dir="rtl"
        />
      </div>
      <div className="form-group">
        <label style={{ display: 'block', textAlign: 'right' }} > : القسم </label>
        <input
          type="text"
          className="form-control"
          placeholder="القسم"
          value={className}
          onChange={(e) => {
            setClassName(e.target.value);
            console.log('Class Name:', e.target.value);
          }}
          required
          style={{ display: 'block', textAlign: 'right' }}
          dir="rtl"
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>

{/* Champ "في" */}
<div style={{ flex: 1, textAlign: 'right' }}>
  <label htmlFor="fi" style={{ display: 'block', marginBottom: '0.5rem' }}> : في</label>
  <input 
    type="text" 
    id="fi" 
    value={valuePlace} 
    onChange={(e) => setValuePlace(e.target.value)} 
    style={{
      width: '100%',
      padding: '0.5rem',
      borderRadius: '4px',
      border: '1px solid #ccc',
      textAlign: 'right',
      direction: 'rtl',
    }}
     dir="rtl"
  />
</div>
 {/* Champ "حرر ب" */}
 <div style={{ flex: 1, marginLeft: '1rem', textAlign: 'right' }}>
  <label htmlFor="harar" style={{ display: 'block', marginBottom: '0.5rem' }} > : حرر ب</label>
  <input 
    type="date" 
    id="harar" 
    value={valueDate} 
    onChange={(e) => setValueDate(e.target.value)} 
    style={{
      width: '100%',
      padding: '0.5rem',
      borderRadius: '4px',
      border: '1px solid #ccc',
      textAlign: 'right',
      direction: 'rtl',
    }}
     dir="rtl"
  />
</div>
</div>
       {/* Nouvelle section pour ملاحظات */}
  <div className="form-group">
    <label style={{ display: 'block', textAlign: 'right' }}> : ملاحظات </label>
    <textarea
      className="form-control"
      placeholder="أضف ملاحظاتك هنا"
      value={notes} // Assurez-vous que la variable `notes` est définie dans votre état
      onChange={(e) => {
        setNotes(e.target.value);
        console.log('Notes:', e.target.value);
      }}
      rows="4"
      style={{
        display: 'block',
        textAlign: 'right',
        resize: 'none', // Empêche le redimensionnement si souhaité
      }}
      dir="rtl"
    />
  </div>

      {/* Section for Council Members */}
      <div className="form-group">
        <label style={{ display: 'block', textAlign: 'right' } } > : أعضاء المجلس </label>
        {members.map((member, index) => (
          <div key={index} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
            <input
              type="text"
              className="form-control"
              value={member.role}
              onChange={(e) => handleMemberChange(index, 'role', e.target.value)}
              placeholder="المهمة"
              required
              style={{ flex: 1, marginRight: '10px' }}
               dir="rtl"
            />
            <input
              type="text"
              className="form-control"
              value={member.fullName}
              onChange={(e) => handleMemberChange(index, 'fullName', e.target.value)}
              placeholder="الاسم الكامل"
              required
              style={{ flex: 1, marginRight: '10px' }}
               dir="rtl"
            />
            {index === members.length - 1 && (
              <button
                type="button"
                onClick={handleAddMember}
                className="btn btn-secondary"
                style={{ marginRight: '5px' }}
              >
                +
              </button>
            )}
            {members.length > 1 && (
              <button
                type="button"
                onClick={() => handleRemoveMember(index)}
                className="btn btn-danger"
              >
                -
              </button>
            )}
          </div>
        ))}
      </div>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '1rem' }}>
       
       <Button 
      variant="outlined" 
      color="secondary" 
      onClick={() => handleGoBack('PageLayout3')}
      sx={{ 
         borderRadius: '8px', 
         borderColor: '#e74c3c', // Couleur de la bordure rouge
         color: '#e74c3c', // Couleur du texte
         padding: '8px 16px', // Ajustez les marges internes
         fontSize: '14px', // Ajustez la taille du texte
         '&:hover': {
              backgroundColor: '#f8d7da', // Couleur au survol
         }
       }}
       startIcon={<ArrowBackIcon />} // Ajoute l'icône au début du texte
       >
        رجوع     
       </Button>

     
     <button
onClick={handleExportPDF}
className="btn btn-secondary"
style={{
 width: '120px', // Largeur fixe
 height: '50px', // Hauteur fixe
 padding: '10px 20px', // Marges internes pour ajuster la taille
 fontSize: '12px', // Taille du texte
 borderRadius: '8px', // Coins arrondis
}}
>
PDF تصدير إلى
</button>
  </Box>
      </div>
  );
};

export default MinutesOfDepartmentCouncilDecision1;

import React, { useState } from 'react';
import '../../StylesCSS/OriginalInstitution.css';
import axios from 'axios';
import { useUser } from '../../context/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const OriginalInstitution = ({ setActiveSection }) => {
  const API_BASE_URL = 'https://al-bawsala-backend-production-f68c.up.railway.app';
  //const API_BASE_URL = 'http://localhost:8082';
  const { userId } = useUser();
  const [formData, setFormData] = useState({
    academie: '',
    direction: '',
    service: '',
    typeInstitution: '',
    institution: '',
    institutionCode: '',
    town: '',
    environment: '',
    Date_reunion_Conseil : '',
    level: '',
    section: '',
    academicYear: '',
    femaleRegistered: '', 
    femaleRemoved: '',     
    femalePresent: '',     
    maleRegistered: '',    
    maleRemoved: '',       
    malePresent: ''  
  });

  const [isPopupVisible, setIsPopupVisible] = useState(false); 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Ajouter le userId au formData
    const dataToSubmit = {
      ...formData,
      user: {
        id: userId
      }
    };
    console.log("date ins", dataToSubmit)
  
    try {
      const response = await axios.post(`${API_BASE_URL}/api/originalinstitution/submit`, dataToSubmit);
      console.log('Form submitted successfully:', response.data);
      setIsPopupVisible(true); 
      setTimeout(() => {
        setIsPopupVisible(false); 
      }, 3000);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };
  
  const handleGoBack = (section) => {
    setActiveSection(section);
    console.log("Retour");
  };

  return (
    <div style={{ maxWidth: '600px', margin: 'auto' }}>
      <h2 style={{ textAlign: 'right' }}> المؤسسة الأصلية </h2>
      <form onSubmit={handleSubmit}>
      <div>
        <div className="custom-form" >
        <div className="form-group-left">
          <div className="input-group">
            <label htmlFor="town">البلدة</label>
            <input
              type="text"
              id="town"
              name="town"
              value={formData.town}
              onChange={handleChange}
              dir="rtl" 
            />
          </div>
          <div className="input-group">
            <label htmlFor="environment">الوسط</label>
            <select
              id="environment"
              name="environment"
              value={formData.environment}
              onChange={handleChange}
              dir="rtl" 
            >
              <option value="">اختر الوسط</option>
              <option value="حضري">حضري</option>
              <option value="قروي">قروي</option>
            </select>
          </div>
          <div className="input-group">
            <label htmlFor="level">المستوي</label>
            <input
              type="text"
              id="level"
              name="level"
              value={formData.level}
              onChange={handleChange}
              dir="rtl" 
            />
          </div>
          <div className="input-group">
            <label htmlFor="section">الشعبة</label>
            <input
              type="text"
              id="section"
              name="section"
              value={formData.section}
              onChange={handleChange}
              dir="rtl" 
            />
          </div>
          <div className="input-group">
            <label htmlFor="academicYear">السنة الدراسية</label>
            <input
              type="text"
              id="academicYear"
              name="academicYear"
              value={formData.academicYear}
              onChange={handleChange}
              dir="rtl" 
            />
          </div>
          <div className="input-group">
            <label htmlFor="Date_reunion_Conseil"> تاريخ انعقاد المجلس</label>
            <input
              type="date"
              id="Date_reunion_Conseil"
              name="Date_reunion_Conseil"
              value={formData.Date_reunion_Conseil}
              onChange={handleChange}
              dir="rtl" 
            />
          </div>
        </div>

        <div className="form-group-right">
          <div className="input-group">
            <label htmlFor="academie">الاكاديمية الجهوية</label>
            <input
              type="text"
              id="academie"
              name="academie"
              value={formData.academie}
              onChange={handleChange}
              dir="rtl" 
            />
          </div>
          <div className="input-group">
            <label htmlFor="direction">المديرية الاقليمية</label>
            <input
              type="text"
              id="direction"
              name="direction"
              value={formData.direction}
              onChange={handleChange}
              dir="rtl" 
            />
          </div>
          <div className="input-group">
            <label htmlFor="service">المصلحة</label>
            <input
              type="text"
              id="service"
              name="service"
              value={formData.service}
              onChange={handleChange}
              dir="rtl" 
            />
          </div>
          <div className="input-group">
            <label htmlFor="typeInstitution">نوع الموسسة</label>
            <select
              id="typeInstitution"
              name="typeInstitution"
              value={formData.typeInstitution}
              onChange={handleChange}
              dir="rtl" 
            >
              <option value="">اختر</option>
              <option value="donnee1"> عام </option>
              <option value="donnee2"> خاص </option>
            </select>
          </div>
          <div className="input-group">
            <label htmlFor="institution">الموسسة</label>
            <input
              type="text"
              id="institution"
              name="institution"
              value={formData.institution}
              onChange={handleChange}
              dir="rtl" 
            />
          </div>
          <div className="input-group">
            <label htmlFor="institutionCode">رمز الموسسة</label>
            <input
              type="text"
              id="institutionCode"
              name="institutionCode"
              value={formData.institutionCode}
              onChange={handleChange}
              dir="rtl" 
            />
          </div>
        </div>
      </div>
      <div className="centered-form">
        <div className="form-group-left">
          <div className="input-group">
            <label>إناث</label>
            <input
              type="number"
              name="femaleRegistered"
              value={formData.femaleRegistered}
              onChange={handleChange}
              placeholder="عدد المسجلون"
              dir="rtl" 
            />
          </div>
          <div className="input-group">
            <input
              type="number"
              name="femaleRemoved"
              value={formData.femaleRemoved}
              onChange={handleChange}
              placeholder="عدد المشطب عليهم" 
              dir="rtl" 
            />
          </div>
          <div className="input-group">
            <input
              type="number"
              name="femalePresent"
              value={formData.femalePresent}
              onChange={handleChange}
              placeholder="  عدد الحاضرون"  
              dir="rtl" 
            />
          </div>
        </div>

        <div className="form-group-right">
          <div className="input-group">
            <label>ذكور</label>
            <input
              type="number"
              name="maleRegistered"
              value={formData.maleRegistered}
              onChange={handleChange}
              placeholder=" عدد المسجلون"
              dir="rtl" 
            />
          </div>
          <div className="input-group">
            <input
              type="number"
              name="maleRemoved"
              value={formData.maleRemoved}
              onChange={handleChange}
              placeholder="عدد المشطب عليهم" 
              dir="rtl" 
            />
          </div>
          <div className="input-group">
            <input
              type="number"
              name="malePresent"
              value={formData.malePresent}
              onChange={handleChange}
              placeholder="  عدد الحاضرون" 
              dir="rtl" 
            />
          </div>
        </div>
      </div>
      </div>

      <div className="buttoncontainer">
  <button onClick={() => handleGoBack('PageLayout2')} className="button backbtn">
    <FontAwesomeIcon icon={faArrowLeft} /> رجوع
  </button>    
  <button type="submit" className="button submitbutton">
    تأكيد
  </button>
</div>

      </form>
      {isPopupVisible && (
        <div className="popup">
          <p>Les données ont été enregistrées avec succès !</p>
        </div>
      )}
    </div>
  );
};

export default OriginalInstitution;

import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Importation du style par défaut de Quill
import '../../StylesCSS/InputNotes.css'; // Importer le fichier CSS pour la personnalisation
import { useUser } from '../../context/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'


const InputNotes  = ({ setActiveSection })  => {
    const API_BASE_URL = 'https://al-bawsala-backend-production-f68c.up.railway.app';
    //const API_BASE_URL = 'http://localhost:8082';

    const [notes, setNotes] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false); // État pour le modal
    const { userId } = useUser();

    const handleChange = (value) => {
        setNotes(value);
    };

    const cleanHtmlToText = (html) => {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = html;
        return tempDiv.innerText; // Récupérer uniquement le texte
    };

    const handleSaveNotes = async () => {
        const cleanedNotes = cleanHtmlToText(notes); // Nettoyer le texte avant l'envoi

        const response = await fetch(`${API_BASE_URL}/api/notes/save`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                notes: cleanedNotes, // Envoyer le texte nettoyé
                userId: userId.toString(), // Convertir l'ID en String
            }),
        });

        if (response.ok) {
            setNotes(''); // Réinitialiser le champ
            setIsModalVisible(true); // Afficher le modal
        } else {
            alert('Erreur lors de l\'enregistrement des notes.');
        }
    };
    const handleCloseModal = () => {
        setIsModalVisible(false); // Fermer le modal
    };
    const handleGoBack = (section) => {
        setActiveSection(section);
        console.log("Retour");
      };

    return (
        <div className="input-notes-container">
            <h2 className="input-notes-title">ادخال الملاحظات</h2>
            <ReactQuill 
                value={notes} 
                onChange={handleChange} 
                className="quill-editor" 
            />
           <div className="button-container">
            <button onClick={() => handleGoBack('PageLayout2')} className="back-btn">
                <FontAwesomeIcon icon={faArrowLeft} /> رجوع
            </button>
            <button className="submit-button" onClick={handleSaveNotes}>إرسال</button>

        </div>

            {/* Modal */}
            {isModalVisible && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h3>تم ارسال الملاحظات بنجاح!</h3>
                        <button onClick={handleCloseModal}>إغلاق</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default InputNotes;

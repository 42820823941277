import React, { useState ,useEffect } from 'react';
import 'jspdf-autotable';
import jsPDF from 'jspdf'; 
import '../../StylesCSS/MinutesOfDepartmentCouncilDecision.css'; // Assurez-vous d'importer ce fichier CSS
import { MenuItem, FormControl, Select, InputLabel, Box, Typography, Button ,TextField } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';



const MinutesOfDepartmentCouncilDecision = ({ setActiveSection })  => {
  const API_BASE_URL = 'https://al-bawsala-backend-production-f68c.up.railway.app';
  //const API_BASE_URL = 'http://localhost:8082';
  
  const [institutions, setInstitutions] = useState(['']);
  const [reasons, setReasons] = useState(['']);
  const [members, setMembers] = useState([{ fullName: '', role: '' }]);
  const [institutionName, setInstitutionName] = useState('');
  const [date, setDate] = useState('');
  const [councilDate, setCouncilDate] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const [studentName, setStudentName] = useState('');
  const [className, setClassName] = useState('');

  const handleInstitutionChange = (index, value) => {
    console.log(`Institution ${index + 1}:`, value);
    const updatedInstitutions = [...institutions];
    updatedInstitutions[index] = value;
    setInstitutions(updatedInstitutions);
  };

  const handleAddInstitution = () => {
    setInstitutions([...institutions, '']);
  };

  const handleRemoveInstitution = (index) => {
    const updatedInstitutions = institutions.filter((_, i) => i !== index);
    setInstitutions(updatedInstitutions);
  };

  const handleReasonChange = (index, value) => {
    console.log(`Reason ${index + 1}:`, value);
    const updatedReasons = [...reasons];
    updatedReasons[index] = value;
    setReasons(updatedReasons);
  };

  const handleAddReason = () => {
    setReasons([...reasons, '']);
  };

  const handleRemoveReason = (index) => {
    const updatedReasons = reasons.filter((_, i) => i !== index);
    setReasons(updatedReasons);
  };

  const handleMemberChange = (index, field, value) => {
    const updatedMembers = [...members];
    updatedMembers[index][field] = value;
    setMembers(updatedMembers);
    console.log(`Member ${index + 1} ${field}:`, value);
  };

  const handleAddMember = () => {
    setMembers([...members, { fullName: '', role: '' }]);
  };

  const handleRemoveMember = (index) => {
    const updatedMembers = members.filter((_, i) => i !== index);
    setMembers(updatedMembers);
  };

  const handleGoBack = (section) => {
    setActiveSection(section);
    console.log("Retour");
  };

  useEffect(() => {
    const loadFonts = async () => {
      try {
        const response = await fetch('/fonts/NotoSansArabic-Regular.ttf');
        if (!response.ok) throw new Error('Font not found');
        const buffer = await response.arrayBuffer();
        const fontData = String.fromCharCode(...new Uint8Array(buffer));
        jsPDF.API.addFileToVFS('NotoSansArabic-Regular.ttf', fontData);
        jsPDF.API.addFont('NotoSansArabic-Regular.ttf', 'NotoSansArabic', 'normal');
      } catch (error) {
        console.error('Error loading font:', error);
      }
    };
  
    loadFonts();
  }, []); // Notez le tableau vide [] pour empêcher les ré-exécutions
  

  const handleExportPDF = async () => {
    const doc = new jsPDF('p', 'pt', 'a4');

    // Ajouter la police arabe
    doc.addFont('/fonts/NotoSansArabic-Regular.ttf', 'NotoSansArabic', 'normal');
    doc.setFont('NotoSansArabic', 'normal');
    doc.setFontSize(12);

    const img = new Image();
    img.src = '/images/Photo1.PNG';
    await new Promise(resolve => {
      img.onload = resolve;
    });
  
    const imgWidth = 250;
    const imgHeight = (img.height * imgWidth) / img.width;
    doc.addImage(img, 'PNG', (doc.internal.pageSize.width - imgWidth) / 2, 10, imgWidth, imgHeight);
  
    doc.setFont('NotoSansArabic');
  
    doc.setFontSize(11);
    doc.setTextColor(0, 0, 0); // Couleur noire pour les trois premières lignes
  
    // Ajouter les trois premiers textes avec les nouvelles propriétés
    doc.text(' الأكاديمية الجهوية:  طنجة - تطوان - الحسيمة', doc.internal.pageSize.width / 2, imgHeight + 15, { align: 'center' });
    doc.text('  مديرية المضيق - الفنيدق   ', doc.internal.pageSize.width / 2, imgHeight + 30, { align: 'center' });
    doc.text('  مصلحة تأطير المؤسسات التعليمية و التوجيه  ', doc.internal.pageSize.width / 2, imgHeight + 45, { align: 'center' });
  
    const pageWidth = doc.internal.pageSize.width;
    const centerX = pageWidth / 2;

    doc.setFontSize(12);
    doc.text('  المؤسسة : ' + institutionName, doc.internal.pageSize.width - 120, imgHeight + 70, { align: 'right' });
    doc.text('في :'  + date , doc.internal.pageSize.width / 2 - 100, imgHeight + 70, { align: 'center' });

    // Titre principal (Conseil)
    doc.setFontSize(14);
    doc.text(': محضر قرار مجلس القسم الخاص بالتوجيه', 300, 180, { align: 'center' });
    doc.setLineWidth(2);
    // Ajouter une ligne sous le texte
    const lineMargin = 180; // Marge de chaque côté de la ligne
    doc.line(lineMargin, 190, pageWidth - lineMargin , 190); 

    // Ajout du texte reçu
    doc.setFontSize(12);
    
    doc.text('  بناء على قرار وزير التربية الوطنية رقم 2071-01 صادر في 7 رمضان  1422 - 23 نوفبر 2001  - بشان النضام', 300, 240, { align: 'center' });
    doc.text(' سالفاالمدرسي في التعليم الأولي والابتدائي والثانوي ، وذلك حسب مقتضيات المادتين 20 و 26 من القرار المذكور ', 300, 260, { align: 'center' });
    doc.text(   ' قرر مجلس القسم الخاص بالتوجيه المنعقد بتاريخ :'  + date + '  بثانوية :  ' + schoolName + '  السماح : '      , 500, 280, { align: 'right' });
    doc.text(' للتلميد : ' + studentName + ' - ' , 540, 300, { align: 'right' });
    doc.text(' من القسم : ' + className + ' - ' , 543, 320, { align: 'right' });

    // Ligne pour "قرار المجلس"
    doc.text(' : و ذلك بالاستفادة من سنة استثنائية و ذلك للأسباب التالية', 550, 340, { align: 'right' });
     // Ajout des raisons sous forme de liste
    let reasonY = 360; // Position Y de départ pour la liste des raisons
    reasons.forEach((reason, index) => {
      doc.text(` ${reason} - `, 540, reasonY, { align: 'right' });
      reasonY += 20; // Espacement vertical entre chaque raison
     });

     doc.setFont('NotoSansArabic');
     



    // Créez le tableau avec autoTable une seule fois
    const tableColumnHeaders = ['التوقيع', 'المادة / المهمة', 'الاسم الكامل'];
    const tableRows = members.map(member => ['', member.role, member.fullName]);
    doc.autoTable({
      head: [tableColumnHeaders],
      body: tableRows,
      startY: 450,
      theme: 'grid',
      styles: { font: 'NotoSansArabic', halign: 'right' },
      columnStyles: {
        0: { cellWidth: 150 },
        1: { cellWidth: 180 },
        2: { cellWidth: 180 },
      },
    });
    doc.setFontSize(11);
    // Pied de page (signature et cachet)
    doc.text(' : خاتم و توقيع رئيس المجلس ', 250, 740);

    // Souligner le texte
    const textWidth = doc.getTextWidth(' : خاتم و توقيع رئيس المجلس ');
    const startX = 250; // Position X de début
    const startY = 743; // Position Y légèrement en dessous du texte
    doc.line(startX, startY, startX + textWidth, startY);    
    doc.text('ملاحظة : يضم هذا القرار الى ملف التلميذ', 495, 780, { align: 'center' });

    // Enregistrement du fichier PDF
    doc.save('محضر_قرار_مجلس_التوجيه.pdf');
};


  return (
    <div className="form-container">
      <h2 className="form-title">      محضر قرار مجلس القسم الخاص بالتوجيه  </h2>

      <div className="form-group">
        <label style={{ display: 'block', textAlign: 'right' }}> : المؤسسة </label>
        <input
          type="text"
          className="form-control"
          placeholder="اسم المؤسسة"
          value={institutionName}
          onChange={(e) => {
            setInstitutionName(e.target.value);
            console.log('Institution Name:', e.target.value);
          }}
          required
          style={{ display: 'block', textAlign: 'right' }}
           dir="rtl"
        />
      </div>

      <div className="form-group">
        <label style={{ display: 'block', textAlign: 'right' }} > : التاريخ   </label>
        <input
          type="date"
          className="form-control"
          value={date}
          onChange={(e) => {
            setDate(e.target.value);
            console.log('Date:', e.target.value);
          }}
          required
          style={{ display: 'block', textAlign: 'right' }}
           dir="rtl"
        />
      </div>

      <div className="form-group">
        <label style={{ display: 'block', textAlign: 'right' }} > : تاريخ انعقاد المجلس  </label>
        <input
          type="date"
          className="form-control"
          value={councilDate}
          onChange={(e) => {
            setCouncilDate(e.target.value);
            console.log('Council Date:', e.target.value);
          }}
          required
          style={{ display: 'block', textAlign: 'right' }}
           dir="rtl"
        />
      </div>

      <div className="form-group">
        <label style={{ display: 'block', textAlign: 'right' }} > : الثانوية</label>
        <input
          type="text"
          className="form-control"
          placeholder="اسم الثانوية"
          value={schoolName}
          onChange={(e) => {
            setSchoolName(e.target.value);
            console.log('School Name:', e.target.value);
          }}
          required
          style={{ display: 'block', textAlign: 'right' }}
          dir="rtl"
        />
      </div>

      <div className="form-group">
        <label style={{ display: 'block', textAlign: 'right' }} > : التلميذ </label>
        <input
          type="text"
          className="form-control"
          placeholder="اسم التلميذ"
          value={studentName}
          onChange={(e) => {
            setStudentName(e.target.value);
            console.log('Student Name:', e.target.value);
          }}
          required
          style={{ display: 'block', textAlign: 'right' }}
          dir="rtl"
        />
      </div>

      <div className="form-group">
        <label style={{ display: 'block', textAlign: 'right' }} > : القسم </label>
        <input
          type="text"
          className="form-control"
          placeholder="القسم"
          value={className}
          onChange={(e) => {
            setClassName(e.target.value);
            console.log('Class Name:', e.target.value);
          }}
          required
          style={{ display: 'block', textAlign: 'right' }}
          dir="rtl"
        />
      </div>

      <div className="form-group" style={{ marginBottom: '15px' }}>
        <label style={{ display: 'block', textAlign: 'right' }} > : الاسباب</label>
        {reasons.map((reason, index) => (
          <div key={index} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
            <input
              type="text"
              className="form-control"
              value={reason}
              onChange={(e) => handleReasonChange(index, e.target.value)}
              placeholder={`سبب ${index + 1}`}
              required
              style={{ flex: 1, marginRight: '10px' , textAlign: 'right' }}
               dir="rtl"
            />
            {index === reasons.length - 1 && (
              <button
                type="button"
                onClick={handleAddReason}
                className="btn btn-secondary"
                style={{ marginRight: '5px' }}
              >
                +
              </button>
            )}
            {reasons.length > 1 && (
              <button
                type="button"
                onClick={() => handleRemoveReason(index)}
                className="btn btn-danger"
              >
                -
              </button>
            )}
          </div>
        ))}
      </div>

      {/* Section for Council Members */}
      <div className="form-group">
        <label style={{ display: 'block', textAlign: 'right' } } > : أعضاء المجلس </label>
        {members.map((member, index) => (
          <div key={index} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
            <input
              type="text"
              className="form-control"
              value={member.role}
              onChange={(e) => handleMemberChange(index, 'role', e.target.value)}
              placeholder="المهمة"
              required
              style={{ flex: 1, marginRight: '10px' }}
              dir="rtl"
            />
            <input
              type="text"
              className="form-control"
              value={member.fullName}
              onChange={(e) => handleMemberChange(index, 'fullName', e.target.value)}
              placeholder="الاسم الكامل"
              required
              style={{ flex: 1, marginRight: '10px' }}
               dir="rtl"
            />
            {index === members.length - 1 && (
              <button
                type="button"
                onClick={handleAddMember}
                className="btn btn-secondary"
                style={{ marginRight: '5px' }}
              >
                +
              </button>
            )}
            {members.length > 1 && (
              <button
                type="button"
                onClick={() => handleRemoveMember(index)}
                className="btn btn-danger"
              >
                -
              </button>
            )}
          </div>
        ))}
      </div>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '1rem' }}>
       
       <Button 
      variant="outlined" 
      color="secondary" 
      onClick={() => handleGoBack('PageLayout3')}
      sx={{ 
         borderRadius: '8px', 
         borderColor: '#e74c3c', // Couleur de la bordure rouge
         color: '#e74c3c', // Couleur du texte
         padding: '8px 16px', // Ajustez les marges internes
         fontSize: '14px', // Ajustez la taille du texte
         '&:hover': {
              backgroundColor: '#f8d7da', // Couleur au survol
         }
       }}
       startIcon={<ArrowBackIcon />} // Ajoute l'icône au début du texte
       >
        رجوع     
       </Button>

     
     <button
onClick={handleExportPDF}
className="btn btn-secondary"
style={{
 width: '120px', // Largeur fixe
 height: '50px', // Hauteur fixe
 padding: '10px 20px', // Marges internes pour ajuster la taille
 fontSize: '12px', // Taille du texte
 borderRadius: '8px', // Coins arrondis
}}
>
PDF تصدير إلى
</button>
  </Box>
      </div>
  );
};

export default MinutesOfDepartmentCouncilDecision;

import React, { useState , useEffect } from 'react';
import { useUser } from '../../context/UserContext';
import axios from 'axios';
import jsPDF from 'jspdf';
import { MenuItem, FormControl, Select, InputLabel, Box, Typography, Button ,TextField } from '@mui/material';
import autoTable from 'jspdf-autotable';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ResultatsDesConseils  = ({ setActiveSection }) => {
  const API_BASE_URL = 'https://al-bawsala-backend-production-f68c.up.railway.app';
  //const API_BASE_URL = 'http://localhost:8082';
  // Variables internes
  const { userId } = useUser(); 
  const [institutions, setInstitutions] = useState([]);
  const [selectedInstitution, setSelectedInstitution] = useState('');
  const [selectedVille, setSelectedVille] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [directorNotes, setDirectorNotes] = useState(''); 
  const [guidanceNotes, setGuidanceNotes] = useState('');

  useEffect(() => {
    axios.get(`${API_BASE_URL}/api/excel/institutions/${userId}`)
        .then(response => setInstitutions(response.data))
        .catch(error => console.error('Erreur lors de la récupération des institutions:', error));
}, [userId]);
  
const handleGoBack = (section) => {
  setActiveSection(section);
  console.log("Retour");
};

const getDistributionStudentsCommonCores = async (selectedInstitution) => {
  const response = await fetch(`${API_BASE_URL}/api/students/DistributionStudentsCommonCores?selectedInstitution=${selectedInstitution}`);
  const data = await response.json();
  return data; // retourne un objet { filles: x, garcons: y }
};

getDistributionStudentsCommonCores(selectedInstitution).then(data => {
  console.log("data",data);
  // Insérer ces valeurs dans le tableau du PDF
});

const generateFinalResults = async (selectedInstitution) => {
  const response = await fetch(`${API_BASE_URL}/api/students/countResult?selectedInstitution=${selectedInstitution}`);
  const data = await response.json();
  return data; // retourne un objet { filles: x, garcons: y }
};

generateFinalResults(selectedInstitution).then(data => {
  console.log("data",data);
  // Insérer ces valeurs dans le tableau du PDF
});



  console.log("institution ->",selectedInstitution);
  console.log("selectedVille", selectedVille);
  console.log("selectedDate", selectedDate);

  


  const handleExportPDF = async () => {
    const data = await getDistributionStudentsCommonCores(selectedInstitution);
    const data2 = await generateFinalResults(selectedInstitution);

    if (!data) {
      console.error("Data not received from API");
      return;
    }
    const doc = new jsPDF();
    // Ajouter les polices
    doc.addFileToVFS('NotoSansArabic-Regular2.ttf', '/fonts/NotoSansArabic-Regular2.ttf');
    doc.addFont('/fonts/NotoSansArabic-Regular2.ttf', 'NotoSansArabic', 'normal');
    doc.setFont('NotoSansArabic');
    doc.setFontSize(12);

    const img = new Image();
    img.src = '/images/Photo1.PNG';
    await new Promise(resolve => {
      img.onload = resolve;
    });
  
    const imgWidth = 80;
    const imgHeight = (img.height * imgWidth) / img.width;
    doc.addImage(img, 'PNG', (doc.internal.pageSize.width - imgWidth) / 2, 10, imgWidth, imgHeight);
  
    doc.setFont('NotoSansArabic');
  
    doc.setFontSize(11);
    doc.setTextColor(0, 0, 0); // Couleur noire pour les trois premières lignes
  
    // Ajouter les trois premiers textes avec les nouvelles propriétés
    doc.text(' الأكاديمية الجهوية:  طنجة - تطوان - الحسيمة', doc.internal.pageSize.width / 2, imgHeight + 15, { align: 'center' });
    doc.text('  مديرية المضيق - الفنيدق   ', doc.internal.pageSize.width / 2, imgHeight + 20, { align: 'center' });
    doc.text('  مصلحة تأطير المؤسسات التعليمية و التوجيه  ', doc.internal.pageSize.width / 2, imgHeight + 25, { align: 'center' });

    // Ajouter le titre principal
    const pageWidth = doc.internal.pageSize.width;

    // Information de l'institution
    doc.text('المؤسسة : ' + selectedInstitution, pageWidth - 20,  imgHeight + 33, { align: 'right' });
    doc.text(' البلدة : ' + selectedVille , 50, imgHeight + 33, { align: 'left' });

    doc.text(' - النتائج العددية لمجالس الاقسام الخاصة بالتوجيه - السنة الثالثة اعدادي ', pageWidth / 2, imgHeight + 45, { align: 'center' });

    doc.setFontSize(8);

    const body2 = [];
    data2.forEach(genderInfo => {
    const genderName = genderInfo.name;
    const genderCount = genderInfo.count;
    body2.push([
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        { content: genderCount, styles: { halign: 'right' } },
        { content: genderName, styles: { halign: 'right' } }
    ]);
    });



    // Structure de la table avec les nouvelles en-têtes imbriquées
    doc.autoTable({
      startY: imgHeight + 50,
      head: [
        [
          { content: 'المفصولون', colSpan: 2 },
          { content: 'المكررون', colSpan: 2 },
          { content: 'الناجحون', colSpan: 3 },
          { content: 'عدد التلاميذ', colSpan: 4 }
        ],
        [
          { content: 'المقبولون بالتكوين المهني', rowSpan: 1 },
          { content: 'المجموع', rowSpan: 1 },
          { content: 'المقبولون بالتكوين المهني', rowSpan: 1 },
          { content: 'المجموع', rowSpan: 1 },
          { content: 'الموجهون الى التكوين المهني', rowSpan: 1 },
          { content: 'الموجهون الى الجدوع المشتركة', rowSpan: 1 },
          { content: 'المجموع', rowSpan: 1 },
          { content: 'الحاضرون', rowSpan: 1 },
          { content: 'المشطب عليهم', rowSpan: 1 },
          { content: 'المسجلون', rowSpan: 1 },
          { content: 'الجنس', rowSpan: 1 }
        ]
      ],
      body: body2,
      theme: 'grid',
      styles: { font: 'NotoSansArabic', fontSize: 8,   halign: 'right' },
      headStyles: { 
        fillColor: [200, 200, 200], 
        textColor: [0, 0, 0],
        lineWidth: 0.5,  
        lineColor: [0, 0, 0] 
      },
      columnStyles: {
        0: { cellWidth: 20 },
        1: { cellWidth: 15 },
        2: { cellWidth: 20 },
        3: { cellWidth: 15 },
        4: { cellWidth: 18 },
        5: { cellWidth: 20 },
        6: { cellWidth: 15 },
        7: { cellWidth: 18 },
        8: { cellWidth: 18 },
        9: { cellWidth: 20 },
        10: { cellWidth: 15 }
      },
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.5,
      margin: { top: 10, right: 6, bottom: 10, left: 10 }
    });
    doc.setFontSize(12);
    doc.text(' توزيع تلاميد السنة الثالثة اعدادي الموجهين الي الجدوع المشتركة', pageWidth / 2, imgHeight + 100, { align: 'center' });

   // TABLE Numero 2

   doc.setFontSize(8);
    const body = [];
    data.forEach(cycle => {
        const cycleName = cycle.name;
        let totalSum = 0;
        let totalMaleSum = 0;
        let totalFemaleSum = 0;
        
      /*  cycle.sexList.forEach(genderInfo => {
            const genderName = genderInfo.name;
            const genderCount = genderInfo.count || 0;
            if (genderName === "ذكر") totalMaleSum += genderCount;
            if (genderName === "أنثى") totalFemaleSum += genderCount;
            totalSum += genderCount;

             body.push([
              '',
              '',
              totalSum, // Total par cycle
              totalMaleSum,
              totalFemaleSum,
              { content: genderName, styles: { halign: 'right' } } ,
              { content: cycleName, styles: { halign: 'right' } , rowSpan: 3},
          ]);
        });
        body.push([
          '',
          '',
          '',
          totalMaleSum, // Replace with actual summation logic if needed
          totalFemaleSum, // Replace with actual summation logic if needed
          { content: 'المجموع', styles: { halign: 'right' } }
        ]); */


        const rows = cycle.sexList.map((genderInfo) => {
          const genderName = genderInfo.name; // "ذكر" ou "أنثى"
          const genderCount = genderInfo.count || 0;
  
          // Mise à jour des totaux
          if (genderName === "ذكر") totalMaleSum += genderCount;
          if (genderName === "أنثى") totalFemaleSum += genderCount;
          totalSum += genderCount;
  
          // Construire la ligne pour chaque genre
          return [
            '', // Cellule vide pour "Cycle Name" (fusionné)
            '',
            '',
            '',
            '',
            genderCount, // Nombre spécifique au genre
            genderName, // Genre (ذكر ou أنثى)
        ];
      });
  
      body.push([
        '',
        '',
        '',
        '',
        totalMaleSum, // Nombre pour "ذكر"
        "ذكر", // Genre "ذكر"
        { content: cycleName, rowSpan: 3, styles: { halign: 'right' } }, // Nom du cycle fusionné sur 3 lignes
    ]);

    body.push([
        '',
        '',
        '',
        '',
        totalFemaleSum, // Nombre pour "أنثى"
        "أنثى", // Genre "أنثى"
        '',
    ]);

    body.push([
        '',
        '',
        '',
        '',
        totalSum, // Total général
        "المجموع", // Texte pour le total
        '',
    ]);
    });

    // Utilisation de `autoTable` pour générer le tableau
    doc.autoTable({
        startY: imgHeight + 110,  // Ajustez la position Y en fonction du reste du document
        head: [
            [
                { content: 'المجموع', styles: { halign: 'center' } , colSpan: 1 },
                { content: 'الاستقبال بالخارجية و الداخلية', styles: { halign: 'center' } , colSpan: 2 },
                { content: 'الاستقبال بالخارجية فقط', styles: { halign: 'center' } , colSpan: 2 },
                { content: 'الجنس', styles: { halign: 'center' } , colSpan: 1},
                { content: ' الجذع المشترك'  , styles: { halign: 'center' } , colSpan: 1 },
            ], 
            [
              { content: '', rowSpan: 1 },
              { content: 'اسم الموسسة', rowSpan: 1 },
              { content: ' العدد', rowSpan: 1 },
              { content: 'اسم الموسسة', rowSpan: 1 },
              { content: ' العدد', rowSpan: 1 },
              { content: '', rowSpan: 1 },
              { content: '', rowSpan: 1 }
            ]
        ],
        body: body,
        theme: 'grid',
        styles: { font: 'NotoSansArabic', fontSize: 10 },
        headStyles: { fillColor: [200, 200, 200], textColor: [0, 0, 0] , lineWidth: 0.5, lineColor: [0, 0, 0]  },
        columnStyles: {
            0: { cellWidth: 20 },
            1: { cellWidth: 25 },
            2: { cellWidth: 20 },
            3: { cellWidth: 35 },
            4: { cellWidth: 20 },
            5: { cellWidth: 25 },
            6: { cellWidth: 50 },

        },
        tableWidth: 195,
        margin: { right: 5, left: 10 },
        tableLineColor: [0, 0, 0],
        tableLineWidth: 0.1,
    });
    doc.setFontSize(10);
    doc.text('تاريخ انعقاد المجلس : ' + selectedDate, pageWidth - 20 , doc.internal.pageSize.height - 55, { align: 'right' });

   const notesTableData = [
    ['ملاحظات وتوقيع إطار التوجيه', 'ملاحظات وتوقيع رئيس المؤسسة'], // Ligne des titres
    [directorNotes, guidanceNotes] // Ligne vide pour les signatures
   ];

// Options pour centrer le tableau au bas de la page
doc.autoTable({
  head: [notesTableData[0]], // En-tête du tableau
  body: [notesTableData[1]], // Contenu du tableau (lignes vides)
  startY: doc.internal.pageSize.height - 50, // Positionner le tableau en bas de la page
  styles: {
    font: 'NotoSansArabic', // Police arabe
    fontSize: 10,
    halign: 'center', // Centrer horizontalement
    cellPadding: 5, // Espacement dans les cellules
  },
  theme: 'grid', // Bordures du tableau
  headStyles: {
    fillColor: [240, 240, 240], // Couleur de fond des en-têtes
    textColor: [0, 0, 0], // Couleur du texte des en-têtes
  },
  columnStyles: {
    0: { cellWidth: (pageWidth - 20) / 2, halign: 'center' }, 
    1: { cellWidth: (pageWidth - 20) / 2, halign: 'center' }, 
  },
});

    // Enregistrer le document
    doc.save('نتائج_مجالس_التوجيه.pdf');
  };

return (
  <>
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        gap: 2, 
        padding: '2rem', 
        backgroundColor: '#f4f6f8', // Couleur de fond douce
        borderRadius: '10px', 
        border: '1px solid #dcdcdc',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        maxWidth: '700px',
        margin: 'auto',
        textAlign: 'right',
      }}
    >
      <Typography 
        variant="h5" 
        sx={{ 
          marginBottom: '1rem', 
          fontWeight: '500', 
          color: '#2c3e50',
          textAlign: 'center'
        }}
      >
- النتائج العددية لمجالس الاقسام الخاصة بالتوجيه - السنة الثالثة اعدادي       
</Typography>

      <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1.5rem' }}>
        <InputLabel
          sx={{
            textAlign: 'right',
            fontSize: '1.1rem',
            marginBottom: '0.5rem',
            color: '#2c3e50',
          }}
        >
          اختر المؤسسة
        </InputLabel>
        <Select
          value={selectedInstitution}
          onChange={(e) => setSelectedInstitution(e.target.value)}
          label="اختر المؤسسة"
          sx={{
            textAlign: 'right',
            direction: 'rtl',
            backgroundColor: '#ffffff',
            '&:hover': {
              backgroundColor: '#f1f1f1',
            },
            '&:focus': {
              borderColor: '#2980b9', // Bleu plus clair lors du focus
            },
          }}
        >
          {institutions.map((institution) => (
            <MenuItem key={institution} value={institution} style={{ textAlign: 'right' }}>
              {institution}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Input pour رمزها */}
      <TextField
        fullWidth
        label="البلدة"
        value={selectedVille}
        onChange={(e) => setSelectedVille(e.target.value)}
        sx={{ marginBottom: '1rem', textAlign: 'right', direction: 'rtl' }}
      />

      <TextField
        fullWidth
        label="تاريخ انعقاد المجلس"
        type="date"
        value={selectedDate}
        onChange={(e) => setSelectedDate(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        sx={{ marginBottom: '1rem', textAlign: 'right', direction: 'rtl' }}
      />
 
      {/* Textarea pour ملاحظات وتوقيع رئيس المؤسسة */}
      <TextField
          fullWidth
          label="ملاحظات وتوقيع رئيس المؤسسة"
          multiline
          rows={4}
          value={directorNotes}
          onChange={(e) => setDirectorNotes(e.target.value)}
          sx={{ marginBottom: '1rem', textAlign: 'right', direction: 'rtl' }}
        />

        {/* Textarea pour ملاحظات وتوقيع إطار التوجيه */}
        <TextField
          fullWidth
          label="ملاحظات وتوقيع إطار التوجيه"
          multiline
          rows={4}
          value={guidanceNotes}
          onChange={(e) => setGuidanceNotes(e.target.value)}
          sx={{ marginBottom: '1rem', textAlign: 'right', direction: 'rtl' }}
        />

      {/* Boutons */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '1rem' }}>

        <Button 
         variant="outlined" 
         color="secondary" 
         onClick={() => handleGoBack('PageLayout3')}
         sx={{ 
            borderRadius: '8px', 
            borderColor: '#e74c3c', // Couleur de la bordure rouge
            color: '#e74c3c', // Couleur du texte
            padding: '8px 16px', // Ajustez les marges internes
            fontSize: '14px', // Ajustez la taille du texte
            '&:hover': {
                 backgroundColor: '#f8d7da', // Couleur au survol
            }
          }}
          startIcon={<ArrowBackIcon />} // Ajoute l'icône au début du texte
          >
           رجوع     
          </Button>
        <div className="form-group" style={{ textAlign: 'center' }}>
          <button onClick={handleExportPDF} className="btn btn-secondary">
          PDF تصدير إلى 
          </button>
        </div>
      </Box>
    </Box>
  </>
);
};

export default ResultatsDesConseils;
